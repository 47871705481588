DMPConnect.InsIdentityResult = {
  InsIdentityResult_NoIdentityFound: 1,
  InsIdentityResult_SingleIdentityFound: 2,
  InsIdentityResult_MultipleIdentitiesFound: 3
};

/**
 * @brief INSi WS #1 transaction: Get the INS from Vitale card data.
 *
 * The transaction is performed on the i-th patient read in vitale card.
 * The Vitale card must have been read before calling this function.
 *
 * @param {int} aVitaleCardIndex        Index of the patient to use as input of the function.
 *
 *  OUT
 *   {
 *       i_insIdentityResult :         // See enum InsIdentityResult above.
 *
 *       if i_insIdentityResult is (2) InsIdentityResult_SingleIdentityFound, the following structure is also returned:
 *
 *       Identity
 *       {
 *         Ins :
 *         {
 *           s_value
 *           s_key
 *           s_oid
 *           s_startDate         // Format YYYY-MM-DD
 *           s_endDate           // Format YYYY-MM-DD
 *         }
 *         s_birthName
 *         s_given
 *         s_birthGiven
 *         i_sex
 *         s_birthDate           // Format YYYY-MM-DD
 *         s_birthPlace          // Format COG ('Code Officiel Géographique' : https://www.insee.fr/fr/information/2560452)
 *         s_lastInsiCallDate    // Format YYYY-MM-DD
 *         History :                 (*) Only present if there is an history
 *         [
 *           {
 *             s_value
 *             s_key
 *             s_oid
 *             s_startDate       // Format YYYY-MM-DD
 *             s_endDate         // Format YYYY-MM-DD
 *           },
 *           ...
 *         ]
 *       }
 *   }
 */
DMPConnect.prototype.hl_getInsFromVitaleCard = function (
  lpsParams,
  aVitaleCardIndex,
  resultCallBack
) {
  var command = {
    LpsInfos: lpsParams,
    i_vitalePatientIndex: aVitaleCardIndex,

    s_commandName: "hl_getInsFromVitaleCard",
    s_sessionId: this.getSessionId()
  };

  return this.sendCommand(command, 60, resultCallBack);
};

/**
 * @brief INSi WS #1 transaction : Get INS from raw Vitale data.
 *
 * @param {object} lpsParams
 * @param {object} aBeneficiaryVitaleData
 * @param {object} aRightHolderVitaleData
 * @param {string} aVitaleSerialNum
 * @param {function} resultCallback
 *
 * IN
 * {
 *     LpsInfos :              // Software-related informations (authorization numbers, etc.)
 *     {
 *        s_idam    [59],      // Software authorization number. From the CNDA.
 *        s_numAM   [ 2],      // IDAM type. Currently must be set to "4" (Authorization number).
 *        s_version [59],      // Software version. No specific format. E.g. "01.00", or "1.0", or "2".
 *        s_instance[59],      // Software instance UUID in its canonical form. See ISO/IEC 9834‐8 et RFC 4122.
 *                             // Must be set, unique and constant per software instance/installation.
 *                             // E.g. "550e8400‐e29b‐41d4‐a716‐446655440000"
 *        s_name   [59],      // The software name.
 *        s_billingNumber[33]  // Billing situation. Either FINESS for institutions or AM number in all other cases. Mandatory for INSi.
 *     },
 *     BeneficiaryVitaleData : // Raw Vitale data of the patient.
 *     {
 *         s_name     [35],
 *         s_birthName[27],
 *         s_givenName[35],
 *         s_birthday [ 8],    // May be 6 digits-long. May be defined in lunar calendar.
 *         i_rank,
 *         i_qualBenef,
 *         NirData :
 *         {
 *             Nir :
 *             {
 *                 s_nir[13],
 *                 s_key[2]
 *             },
 *             CertifiedNir :
 *             {
 *                 s_nir[13],
 *                 s_key[2]
 *             },
 *             s_certificationDate[8]
 *         },
 *         HealthCoverage :
 *         {
 *             s_regime[2],    // E.g. "01"
 *             s_desk  [3],    // E.g. "349"  a.k.a "Organisme", "Caisse".
 *             s_center[4]     // E.g. "9881" a.k.a "Entité de gestion", "Centre".
 *         }
 *    },
 *    RightHolderVitaleData : // Raw Vitale data of the Vitale card holder.
 *    {
 *         s_birthName     [27],
 *         NirData :
 *         {
 *             Nir :
 *             {
 *                 s_nir[13],
 *                 s_key[2]
 *             },
 *         },
 *    },
 *    s_serialNumber
 * }
 * OUT
 * {
 *    i_insIdentityResult :         // enum InsIdentityResult
 *
 *  // if i_insIdentityResult is (2) InsIdentityResult_SingleIdentityFound:
 *
 *    Ins :
 *    {
 *      s_value
 *      s_key
 *      s_oid
 *      s_startDate         // Format YYYY-MM-DD
 *      s_endDate           // Format YYYY-MM-DD
 *    }
 *    s_birthName
 *    s_given
 *    s_birthGiven
 *    i_sex
 *    s_birthDate           // Format YYYY-MM-DD
 *    s_birthPlace          // Format COG ('Code Officiel Géographique' : https://www.insee.fr/fr/information/2560452)
 *    s_lastInsiCallDate    // Format YYYY-MM-DD
 *    History :                 (*) Only present if there is an history
 *    [
 *      {
 *        s_value
 *        s_key
 *        s_oid
 *        s_startDate       // Format YYYY-MM-DD
 *        s_endDate         // Format YYYY-MM-DD
 *      },
 *      ...
 *    ]
 * }
 *
 */
DMPConnect.prototype.hl_getInsFromRawVitaleData = function (
  lpsParams,
  aBeneficiaryVitaleData,
  aRightHolderVitaleData,
  aVitaleSerialNum,
  resultCallback
) {
  var command = {
    LpsInfos: lpsParams,
    BeneficiaryVitaleData: aBeneficiaryVitaleData,
    RightHolderVitaleData: aRightHolderVitaleData,
    s_serialNumber: aVitaleSerialNum,

    s_commandName: "hl_getInsFromRawVitaleData",
    s_sessionId: this.getSessionId()
  };

  return this.sendCommand(command, 60, resultCallback);
};

/**
 * @brief INSi WS #2 transaction: Get the INS from manually defined patient data.
 *
 * Input requirements:
 *
 * The input strings of this transaction must:
 * - only contains capitals letters ;
 * - not contain symbols (no accent, no cedilla) ;
 * - only contains hyphen ('-') and space (' ') as special characters, and hyphen and space cannot be doubled.
 *
 * The sex can not be "unknown".
 *
 * The expected date format is YYYY-MM-DD.
 *
 * The birth place format is COG (Code Officiel Géographique, @see https://www.insee.fr/fr/information/2560452 )
 *
 * @param {string} name        (Birth) name of the patient
 * @param {string} given       Given of the patient
 * @param {int}    sex         Sex of the patient (Values in DMPConnect.Sex enum)
 * @param {string} date        Birth date (format YYYY-MM-DD)
 * @param {string} place       (Optional) Birth place
 *
 *  OUT
 *   {
 *       i_insIdentityResult :         // enum InsIdentityResult
 *
 *       if i_insIdentityResult is (2) InsIdentityResult_SingleIdentityFound, the following structure is also returned:
 *
 *       Identity
 *       {
 *         Ins :
 *         {
 *           s_value
 *           s_key
 *           s_oid
 *           s_startDate         // Format YYYY-MM-DD
 *           s_endDate           // Format YYYY-MM-DD
 *         }
 *         s_birthName
 *         s_given
 *         s_birthGiven
 *         i_sex
 *         s_birthDate           // Format YYYY-MM-DD
 *         s_birthPlace          // Format COG ('Code Officiel Géographique' : https://www.insee.fr/fr/information/2560452)
 *         s_lastInsiCallDate    // Format YYYY-MM-DD
 *         History :                 (*) Only present if there is an history
 *         [
 *           {
 *             s_value
 *             s_key
 *             s_oid
 *             s_startDate       // Format YYYY-MM-DD
 *             s_endDate         // Format YYYY-MM-DD
 *           },
 *           ...
 *         ]
 *       }
 *   }
 */
DMPConnect.prototype.hl_getInsFromIdentityInformation = function (
  lpsParams,
  name,
  given,
  sex,
  date,
  place,
  resultCallBack
) {
  var command = {
    LpsInfos: lpsParams,

    s_birthName: name,
    s_given: given,
    i_sex: sex,
    s_birthDate: date,
    s_birthPlace: place,

    s_commandName: "hl_getInsFromIdentityInformation",
    s_sessionId: this.getSessionId()
  };

  return this.sendCommand(command, 60, resultCallBack);
};

/**
 * @brief INSi WS #3 transaction: Check the INS validity wrt the patient identity informations.
 *
 * IN
 * {
 *    LpsInfos :              // Software-related informations (authorization numbers, etc.)
 *     {
 *        s_idam    [59],      // Software authorization number. From the CNDA.
 *        s_numAM   [ 2],      // IDAM type. Currently must be set to "4" (Authorization number).
 *        s_version [59],      // Software version. No specific format. E.g. "01.00", or "1.0", or "2".
 *        s_instance[59],      // Software instance UUID in its canonical form. See ISO/IEC 9834-8 et RFC 4122.
 *                             // Must be set, unique and constant per software instance/installation.
 *                             // E.g. "110e8400-e29b-11d4-a716-446655440000"
 *        s_name    [59],      // The software name.
 *        s_billingNumber[33]  // Billing situation. Either FINESS for institutions or AM number in all other cases. Mandatory for INSi.
 *     },
 *         s_ins[13],
 *         s_key[2],
 *         s_oid[32],
 *         s_birthName[80],
 *         s_given[61],
 *         i_sex,
 *         s_birthDate[10],          // Format YYYY-MM-DD
 *     (*) s_birthPlace[5],          // Format COG ('Code Officiel Géographique' : https://www.insee.fr/fr/information/2560452)
 *  }
 * OUT
 * {
 *     "Ok" if validation succeeded or ERROR if validation failed (check error detail for more infos)
 * }
 */
DMPConnect.prototype.hl_checkInsIdentity = function (
  lpsParams,
  ins,
  key,
  oid,
  name,
  given,
  sex,
  date,
  place,
  resultCallBack
) {
  var command = {
    LpsInfos: lpsParams,

    s_ins: ins,
    s_key: key,
    s_oid: oid,
    s_birthName: name,
    s_given: given,
    i_sex: sex,
    s_birthDate: date,
    s_birthPlace: place,

    s_commandName: "hl_checkInsIdentity",
    s_sessionId: this.getSessionId()
  };

  return this.sendCommand(command, 60, resultCallBack);
};

/**
 * hl_getCpxCard must have been called.
 *
 * Optional parameters that can be set in 'optionalParameters' parameter:
 *   HTTP proxy:
 *     "s_proxyIpOrUrl"
 *     "i_proxyPort"
 *     "s_proxyLogin"
 *     "s_proxyPassword"
 *  Custom NTP server:
 *     "s_ntpIpOrUrl"
 *     "i_ntpPort"
 *
 * Returns :
 * - s_instanceUniqueId the instance unique ID of the software. The number is unique per user account and do not change
 *                      between restarts or software updates. Not used here for TLSi transactions.
 * - i_connectionHandle the handle of the connection used for TLSi transactions
 *
 * { [...]
 *    "s_instanceUniqueId" : "2.25.186812548863990774547154938929646584355"
 *    "i_connectionHandle" : 262145
 * }
 *
         The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_createInsiConnector = function (
  pinCode,
  serverFullUrl,
  transactionsTimeoutInSeconds,
  practiceSetting,
  cpsPracticeLocationIndice,
  optionalParameters,
  resultCallback
) {
  var serverUrl = "";

  // Empty string => qualification server.
  if (serverFullUrl === "")
    serverUrl = "https://qualiflps.services-ps.ameli.fr";
  else serverUrl = serverFullUrl;

  var command = {
    s_commandName: "hl_createTlsiConnector",
    s_tlsiUrl: serverUrl,
    i_transactionsTimeout: transactionsTimeoutInSeconds,
    s_practiceSetting: practiceSetting,
    i_cpsPracticeLocationIndice: cpsPracticeLocationIndice,
    i_getConnectionHandle: 1,
    s_sessionId: this.getSessionId()
  };

  if (pinCode.length == 4) {
    command.s_pinCode = pinCode;
  }
  if (pinCode.length > 4) {
    command.s_authenticationToken = pinCode;
  }

  // Merge the HTTP proxy parameters and/or the NTP proxy parameters set in optionalParameters.
  if (optionalParameters) {
    for (var p in optionalParameters) {
      command[p] = optionalParameters[p];
    }
  }

  // Get connection handle
  return this.sendCommand(command, 20, resultCallback);
};

/**
 * @brief Submit batch of verification ( WS_INS4 WebService)
 *
 *  IN
 * {
 *      LpsInfos :              // Software-related informations (authorization numbers, etc.)
 *     {
 *        s_idam    [59],      // Software authorization number. From the CNDA.
 *        s_numAM   [ 2],      // IDAM type. Currently must be set to "4" (Authorization number).
 *        s_version [59],      // Software version. No specific format. E.g. "01.00", or "1.0", or "2".
 *        s_instance[59],      // Software instance UUID in its canonical form. See ISO/IEC 9834‐8 et RFC 4122.
 *                             // Must be set, unique and constant per software instance/installation.
 *                             // E.g. "550e8400‐e29b‐41d4‐a716‐446655440000"
 *        s_name    [59],      // The software name.
 *        s_billingNumber[33]  // Billing situation. Either FINESS for institutions or AM number in all other cases. Mandatory for INSi.
 *     },
 *    Patients:
 *    [
 *      {
 *        s_ins[13],
 *        s_key[2],
 *        s_oid[32],
 *        s_birthName[80],
 *        s_given[61],
 *        s_birthGiven[61],
 *        i_sex,
 *        s_birthDate[10],          // Format YYYY-MM-DD
 *    (*) s_birthPlace[5],          // Format COG ('Code Officiel Géographique' : https://www.insee.fr/fr/information/2560452)
 *      },
 *      {
 *             ...
 *      }
 *    ]
 * }
 * OUT
 * {
 *   s_batchId            // Identifier of the batch (needed to get the result - hl_getInsiBatchValidationSetResult).
 *   i_estimationDelay    // In seconds.
 *
 *   s_processingDate     // Date when the processing started.
 *   s_submissionDate     // Date when the submission occured.
 *   i_requestSize        // Number of patient submitted.
 * }
 */
DMPConnect.prototype.hl_submitInsiBatchValidationSet = function (
  lpsParams,
  patientList,
  resultCallBack
) {
  var command = {
    s_commandName: "hl_submitInsiBatchValidationSet",
    s_sessionId: this.getSessionId(),

    LpsInfos: lpsParams,
    Patients: patientList
  };

  return this.sendCommand(command, 20, resultCallBack);
};

/**
 * @brief Get result of batch verification.
 *
 *  IN
 *  {
 *    s_batchId           // Identifier of the batch we want the result of (batchId is in the result of hl_submitInsiBatchValidationSet)
 *  }
 *  OUT
 * {
 *    i_batchValidationResult     // 1,2,3,4.
 *    s_batchValidationResult
 *    (*) i_estimationDelay       // New delay if computation is not yet finished.
 *    Result :
 *    [
 *      {
 *        i_validationResult      // 1 -> valid ; 2 -> invalid.
 *        "Ins":                  // Only present if validation is Ok
 *        {
 *            "s_ins"
 *            "s_key"
 *            "s_oid"
 *        }
 *      }
 *    ]
 * }
 *
 */
DMPConnect.prototype.hl_getInsiBatchValidationSetResult = function (
  lpsParams,
  batchId,
  resultCallBack
) {
  var command = {
    s_commandName: "hl_getInsiBatchValidationSetResult",
    s_sessionId: this.getSessionId(),

    LpsInfos: lpsParams,
    s_batchId: batchId
  };

  return this.sendCommand(command, 20, resultCallBack);
};

/**
 * @brief get DataMatrix related to a patient.
 *
 * IN
 * {
 *     (*) i_getBinary
 *     (*) i_getBmpImage
 *     Identity                // result of INSI service.
 * }
 * OUT
 * {
 *     DataMatrixBinaryArray  : []             // Only if i_getBinary is 1 (2D array, data is Row wise).
 *     i_dataMatrixBinaryHeight                // Only if i_getBinary is 1
 *     i_dataMatrixBinaryWidth                 // Only if i_getBinary is 1
 *     s_dataMatrixBmpImageContentInBase64     // Only if i_getBmpImage is 1
 * }
 *
 */
DMPConnect.prototype.hl_getDataMatrix = function (
  Identity,
  i_getBinary,
  i_getBmpImage,
  i_getCode,
  resultCallBack
) {
  var command = {};

  command.s_commandName = "hl_getDataMatrix";
  command.s_sessionId = this.getSessionId();
  command.Identity = Identity;
  command.i_getBinary = i_getBinary; // Optional
  command.i_getBmpImage = i_getBmpImage; // Optional
  command.i_getCode = i_getCode; // In future release.

  return this.sendCommand(command, 20, resultCallBack);
};
